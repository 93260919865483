export const primaryColors = [
  "color-1",
  "color-2",
  "color-3",
  "color-4",
  "color-5",
  "color-6",
];

export const backgroundColors = ["bg-1", "bg-2"];
